import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import Navbar from "../Layout/Navbar";
import {Link, Redirect} from "react-router-dom";
import MUIDataTable from "mui-datatables";

import userData from "../../utils/userData";
import {LinearProgress} from "@mui/material";
import UserActions from "../../actions/UserActions";

const user = userData();

export default function QuotationList() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    if (errors.server) {
        console.log(errors.server);
    }

    // data table
    const columns = [
        // "ID",
        "Name",
        "Email",
        "Phone",
        // "company",
        // "more_info",
        "request",
        // "state",
        "date",
        "",
        ""
    ];
    const options = {
        filterType: 'checkbox',
        selectableRows: false,
        rowHover: true,
        filter: true,
        print: true,
        download: true,
        responsive: "scroll"
    };
    let userData = [];

    function fetchData() {
        setLoading(true);
        UserActions.GetQuotations().then(res => {
            setData(res.data);
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setErrors(e.response.data);
            setLoading(false);
        })
    }

    function openQuotation(id) {
        window.location.href = `/quotations/${id}`;
    }

    function deleteData(id) {
        setLoading(true);
        UserActions.DeleteQuotation(id).then(res => {
            console.log(res);
            setLoading(false);
            window.location.reload();
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }

    data.map(quotation =>
        userData.push(
            [
                // quotation._id,
                quotation.name,
                quotation.email,
                quotation.tel,
                // quotation.company,
                // quotation.more_info,
                quotation.request,
                // quotation.state,
                (quotation.date).toString().split('T')[0],
                <button className={"btn btn-primary"} onClick={() => openQuotation(quotation._id)}>More</button>,
                <button className={"btn btn-danger"} onClick={() => deleteData(quotation._id)}>Delete</button>
            ]
        )
    )

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div>
            {/* user.role === "user" || user.role === "admin" ?
                user.role === "user" ?
                    user.status === true ?
                        <Redirect to="/notes/1"/> :
                        <Redirect to="/reset"/> :
                    ""
                : <Redirect to="/"/> */}
            {loading === true ? <LinearProgress/> : ""}
            <Navbar/>
            <Helmet>
                <title>Quotations</title>
            </Helmet>
            <div className={"form-container con-mid"} style={{marginTop: "56px"}}>
                <h3>Quotations</h3>
                <br/>

                <div className={"tbl-holder"}>
                    <MUIDataTable
                        title={"Quotation List"}
                        data={userData}
                        columns={columns}
                        options={options}
                    />
                </div>
            </div>
        </div>
    );
}