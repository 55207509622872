import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import Navbar from "../Layout/Navbar";
import {Link, Redirect} from "react-router-dom";
import MUIDataTable from "mui-datatables";

import userData from "../../utils/userData";
import {LinearProgress} from "@mui/material";
import UserActions from "../../actions/UserActions";

const user = userData();

export default function SingleQuotation() {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const qid = window.location.href.split('/').pop();

    if (errors.server) {
        console.log(errors.server);
    }

    // data table
    const columns = [
        // "ID",
        "Name",
        "Email",
        "Phone",
        // "company",
        // "more_info",
        "request",
        // "state",
        "date",
        "",
        ""
    ];
    const options = {
        filterType: 'checkbox',
        selectableRows: false,
        rowHover: true,
        filter: true,
        print: true,
        download: true,
        responsive: "scroll"
    };
    let userData = [];

    function fetchData(qid) {
        setLoading(true);
        UserActions.GetQuotation(qid).then(res => {
            setData(res.data);
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setErrors(e.response.data);
            setLoading(false);
        })
    }

    function deleteData(id) {
        setLoading(true);
        UserActions.DeleteQuotation(id).then(res => {
            console.log(res);
            setLoading(false);
            window.location.reload();
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }

    function back () {
        window.location.href = "/quotations";
    }

    useEffect(() => {
        fetchData(qid)
    }, [])

    return (
        <div>
            {user.role === "user" || user.role === "admin" ?
                user.role === "user" ?
                    user.status === true ?
                        "" :
                        <Redirect to="/reset"/> :
                    ""
                : <Redirect to="/"/>}
            {loading === true ? <LinearProgress/> : ""}
            <Navbar/>
            <Helmet>
                <title>Quotations</title>
            </Helmet>
            <div className={"form-container container"} style={{paddingTop: "30px", marginTop: "56px"}}>
                <div className={"tbl-holder"}>
                    <div>
                        <div><h1>{data.name}</h1></div>
                        <div><h2>{data.company}</h2></div>
                        <div><br/></div>
                        <div><b>Email:</b> {data.email}</div>
                        <div><b>Tel:</b> {data.tel}</div>
                        <div><b>Request:</b> {data.request}</div>
                        <div><b>Company:</b> {data.company}</div>
                        <div><b>State:</b> {data.state}</div>
                        <div><b>More Info:</b></div><div>{data.more_info}</div>
                        {/*<ol><b>Date:</b> {data.date.split('T')[0].to}</ol>*/}
                        <br/>
                        <div><button className={"btn btn-primary"} onClick={back}>Back</button></div>
                    </div>
                </div>
            </div>
        </div>
    );
}