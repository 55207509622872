import React, {Component} from 'react';
import {Switch, Route} from "react-router-dom";

// components
import Login from "./components/Login";
import Reset from "./components/Reset";

import CreateUser from "./components/Admin/CreateUser";
import UserList from "./components/Admin/UserList";

import CreateNotes from "./components/User/CreateNotes";
import Notes from "./components/User/Notes";
import UpdateNotes from "./components/User/UpdateNotes";

import NotFound from "./components/Layout/NotFound";
import Home from "./components/Home";
import About from "./components/About";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import QuotationForm from "./components/QuotationForm";
import Quotation from "./components/Quotation";
import QuotationList from "./components/User/QuotationList";
import SingleQuotation from "./components/User/SingleQuotation";

class App extends Component {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact path={"/"} component={Home} />
                    <Route exact path={"/about"} component={About} />
                    <Route exact path={"/gallery"} component={Gallery} />
                    <Route exact path={"/contact"} component={Contact} />
                    {/*<Route exact path={"/quotation-form"} component={QuotationForm} />*/}
                    <Route exact path={"/quotation"} component={QuotationForm} />
                    {/*<Route exact path={"/quotation"} component={Quotation} />*/}
                    <Route exact path={"/login"} component={Login} />
                    <Route exact path={"/reset"} component={Reset} />

                    {/* Admin */}
                    <Route exact path={"/create-user"} component={CreateUser} />
                    <Route exact path={"/users"} component={UserList} />
                    <Route exact path={"/quotations"} component={QuotationList} />
                    <Route exact path={"/quotations/:id"} component={SingleQuotation} />

                    {/* User */}
                    {/*<Route exact path={"/create-note"} component={CreateNotes} />*/}
                    {/*<Route exact path={"/notes/:page"} component={Notes} />*/}
                    {/*<Route exact path={"/note/update/:id"} component={UpdateNotes} />*/}
                    {/*<Route exact path={"/update-note"} component={UpdateNotes} />*/}

                    <Route exact component={NotFound} />
                </Switch>
            </div>
        );
    }
}

export default App;
